
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Popins-Thin';
  src: url('/src/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-ThinItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-ExtraLight';
  src: url('/src/assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-ExtraLightItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-Light';
  src: url('/src/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-LightItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-Regular';
  src: url('/src/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-Italic';
  src: url('/src/assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-Medium';
  src: url('/src/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-MediumItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-SemiBold';
  src: url('/src/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-SemiBoldItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-Bold';
  src: url('/src/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Popins-BoldItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-ExtraBold';
  src: url('/src/assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-ExtraBoldItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Popins-Black';
  src: url('/src/assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Popins-BlackItalic';
  src: url('/src/assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}



.dark-gradient {
  background: linear-gradient(180deg, #1F2125 0%, #3F4349 50%, #959DA9 100%);
}

/* Dark mode gradient for the menu item */
.menu-item-dark-gradient {
  background-image: linear-gradient(180deg, #282C32 0%, #4F5868 100%);
}

/* Dark mode gradient for the icon container */
.icon-container-dark-gradient {
  background-image: linear-gradient(180deg, #1F2125, #3F4349);
}

/* General border style for dark mode */
.dark-border {
  border-color: rgba(255, 255, 255, 0.6);
}

/* Dark mode text color */
.text-dark-mode {
  color: white;
}

/* Light mode text color for active items */
.text-active-light {
  color: black;
}


html {
	scroll-behavior: smooth;
}

.ReactModal__Content {
	margin-top: 4em;
}

.latir-logo {
  animation: pulse 1.5s infinite;
}

#pen-angled {
	fill: #222222;
	animation: writing-pen 0.2s infinite alternate;
	transform-origin: center bottom;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease forwards;
}

.animate-bounce {
  animation: bounce 3s infinite;
}

@keyframes writing-pen {
	0% {
		transform: translateY(0) rotate(0deg);
	}
	50% {
		transform: translateY(0px) rotate(5deg);
	}
	100% {
		transform: translateY(0px) rotate(-4deg);
	}
}

@keyframes pulse {
	0%{
		transform:scale(1);
	}
  20%{
    transform:scale(1.2);
    filter: drop-shadow(0 0 34px #00496B);
  }
	40%{
		transform:scale(1);
    filter: drop-shadow(0 0 0 #00496B);
	}
	60%{
    transform:scale(1.2);
    filter: drop-shadow(0 0 34px #00496B);
	}
	80%{
		transform:scale(1);
	}
  100%{
		transform:scale(1);
  }
}